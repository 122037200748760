import { setCookie } from 'nookies'
import { createContext, FC, useCallback, useContext, useEffect, useState, useMemo } from 'react'

import { DEFAULT_BREAKPOINTS } from '~services/constants'
import { isInFrame as isInFrameFunc } from '~utils'

const isBrowser = !!(typeof window !== 'undefined' && window.document)

const getScreenType = () => {
  if (isBrowser) {
    if (DEFAULT_BREAKPOINTS.xxl && window.innerWidth >= DEFAULT_BREAKPOINTS.xxl) return 'xxl'
    if (DEFAULT_BREAKPOINTS.xl && window.innerWidth >= DEFAULT_BREAKPOINTS.xl) return 'xl'
    if (DEFAULT_BREAKPOINTS.lg && window.innerWidth >= DEFAULT_BREAKPOINTS.lg) return 'lg'
    if (DEFAULT_BREAKPOINTS.md && window.innerWidth >= DEFAULT_BREAKPOINTS.md) return 'md'
    if (DEFAULT_BREAKPOINTS.sm && window.innerWidth >= DEFAULT_BREAKPOINTS.sm) return 'sm'

    return 'xs'
  }

  return null
}

type ScreenClass = ThemeScreenClassSizesT

type ScreenClassContext = {
  screenClass: ScreenClass
  isMobile: boolean
  isDesktop: boolean
}

type ProviderProps = {
  screenClassSSR: ScreenClass
}

const Context = createContext<ScreenClassContext>({} as ScreenClassContext)

export const useStore = () => useContext(Context)

const Provider: FC<ProviderProps> = ({ screenClassSSR, children }) => {
  const [isMounted, setIsMounted] = useState(!screenClassSSR)

  const [screenClass, setScreenClass] = useState(getScreenType())

  const handleWindowResized = useCallback(() => {
    setScreenClass(getScreenType())
  }, [])

  const realScreenClass = useMemo(
    () => (isMounted ? (screenClass as ThemeScreenClassSizesT) : screenClassSSR),
    [isMounted, screenClass, screenClassSSR]
  )

  useEffect(() => {
    if (isBrowser && !isMounted) {
      setIsMounted(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isInFrame = isInFrameFunc()

  useEffect(() => {
    if (!isInFrame) {
      setCookie(null, 'screenClass', screenClass, {})
    }
  }, [isInFrame, screenClass])

  useEffect(() => {
    window.addEventListener('resize', handleWindowResized, false)
    return () => {
      window.removeEventListener('resize', handleWindowResized, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const responsiveValues = useMemo(() => {
    const isMobile =
      realScreenClass === 'xs' || realScreenClass === 'sm' || realScreenClass === 'md'

    return {
      screenClass: realScreenClass,
      isMobile,
      isDesktop: !isMobile,
    }
  }, [realScreenClass])

  return <Context.Provider value={responsiveValues}>{children}</Context.Provider>
}
export default Provider
