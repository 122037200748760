import classnames from 'classnames'
import { ButtonHTMLAttributes, FC } from 'react'

import Icon, { IconTypes, IconOptions } from '~ui/Icon'

import styles from './IconButton.module.scss'

type ButtonOptions = {
  iconName: IconTypes
  iconProps?: Partial<IconOptions>
  indicator?: string | number
  onClick?: ButtonClickEventT
  className?: string
  'aria-label'?: string
} & ButtonHTMLAttributes<HTMLButtonElement>

const IconButton: FC<ButtonOptions> = ({ iconName, indicator, className, iconProps, ...props }) => (
  <button
    type="button"
    className={classnames(styles.button_circle, { 'animate-pulse': props.disabled }, className)}
    aria-label={props['aria-label'] || iconName}
    {...props}
  >
    <Icon {...iconProps} name={iconName} />
    {indicator && <span className={styles.indicator}>{indicator}</span>}
  </button>
)

export default IconButton
