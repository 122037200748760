import { Variables } from 'hooks/useVariables'
import { useState, useEffect, ComponentType, FC } from 'react'
import { InputPassword, notifyError, notify } from '~shared'
import { Grid, Button } from '~ui'

const LOCAL_STORAGE_KEY = 'local-storage-key'

type WithFirewallProps = {
  variables: Variables
  isPrivate: boolean
}

function withFirewall<P extends object>(Component: ComponentType<P>): FC<P & WithFirewallProps> {
  return ({ variables, isPrivate = false, ...props }: WithFirewallProps) => {
    const [privatePagePass, setPrivatePagePass] = useState<null | string>(() => {
      if (typeof window !== 'undefined' && JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))) {
        return variables.PRIVATE_PAGE_PASS
      }
      return null
    })

    useEffect(() => {
      if (isPrivate && privatePagePass === variables.PRIVATE_PAGE_PASS) {
        notify("You're viewing the private page")
      }
    }, [isPrivate, privatePagePass])

    const setCurrentPassword = (e) => {
      e.preventDefault()
      const { value } = e.currentTarget.password
      setPrivatePagePass(value)

      if (value === variables.PRIVATE_PAGE_PASS) {
        try {
          localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(true))
        } catch {
          notifyError("The password wasn't saved locally. Try to clear your local storage")
        }
      }
    }

    if (isPrivate && variables && privatePagePass !== variables.PRIVATE_PAGE_PASS) {
      return (
        <form onSubmit={setCurrentPassword}>
          <Grid gap={10} width={400} margin="40vh auto">
            <InputPassword />
            <Button type="submit">Show private page</Button>
          </Grid>
        </form>
      )
    }

    return <Component {...(props as P)} />
  }
}

export default withFirewall
