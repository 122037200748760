import { useQuery, QueryHookOptions, QueryResult } from '@apollo/client'

import { USER_BASE_DATA } from 'gql/queries'
import { UserBaseDataQuery } from 'gql'

type UseUserResult = [UserBaseDataQuery['currentUser'], Partial<QueryResult>]

const useUser = (options?: QueryHookOptions): UseUserResult => {
  const { data: user, ...rest } = useQuery<UserBaseDataQuery>(USER_BASE_DATA, options)

  return [user?.currentUser, rest]
}

export default useUser
