import classNames from 'classnames'
import { FC } from 'react'

import styles from './logo.module.scss'

type LogoProps = {
  big?: boolean
}

const Logo: FC<LogoProps> = ({ big }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={classNames(styles.logo, { [styles.big]: big })}
    id="body_1"
    width="180"
    height="42"
    shapeRendering="optimizeQuality"
  >
    <g transform="matrix(0.07006618 0 0 0.07006618 -0 0.050214663)">
      <g strokeWidth="25" filter="blur(15px)">
        <path
          d="M345.667 302.941L345.667 343.148L377.573 343.148L377.573 359.591C 359.12 383.601 330.121 397.107 298.11 397.107C 241.996 397.107 196.427 350.212 196.427 293.562C 196.427 236.537 241.996 191.893 298.11 191.893C 328.615 191.893 355.354 205.399 374.184 227.158L374.184 227.158L403.936 199.021C 377.95 169.758 339.913 151 298.11 151C 219.023 151 155 213.652 155 293.562C 155 373.097 219.023 438 298.11 438C 347.822 438 391.884 413.99 417.117 374.973C 418.247 373.097 419 370.846 419 368.595L419 368.595L419 302.941L345.667 302.941z"
          stroke="#1be11d"
          fill="none"
        />
        <path
          d="M705.198 434L752.102 434L681.933 334.188C 721.708 324.807 750.601 291.786 750.601 249.009C 750.601 198.353 709.701 156.327 659.044 156.327L659.044 156.327L545.723 156.327C 533.716 156.327 523.584 166.083 523.584 178.09L523.584 178.09L523.584 434L564.86 434L564.86 336.814L637.656 336.814L705.198 434zM564.86 197.602L659.044 197.602C 686.811 197.602 709.326 221.242 709.326 249.009C 709.326 276.402 687.562 298.165 659.044 298.165L659.044 298.165L564.86 298.165L564.86 197.602z"
          stroke="#1be11d"
          fill="none"
        />
        <path
          d="M1085.41 434L971.713 165.332C 968.336 157.077 961.207 152.199 952.576 152.199C 943.946 152.199 936.066 157.077 932.689 165.332L932.689 165.332L817.867 434L864.021 434L952.201 228.372L1039.26 434L1085.41 434z"
          stroke="#1be11d"
          fill="none"
        />
        <path
          d="M1366.24 156.327L1366.24 362.706L1206.76 159.328C 1202.64 154.075 1196.63 151.073 1189.88 151.073C 1177.87 151.073 1168.12 160.829 1168.12 172.837L1168.12 172.837L1168.12 434L1209.39 434L1209.39 228.747L1369.24 429.872C 1372.99 434.751 1379 438.128 1386.13 438.128C 1398.13 438.128 1407.52 428.372 1407.52 416.364L1407.52 416.364L1407.52 156.327L1366.24 156.327z"
          stroke="#1be11d"
          fill="none"
        />
        <path
          d="M1733.73 156.327L1497.33 156.327L1497.33 197.602L1594.89 197.602L1594.89 434L1636.17 434L1636.17 197.602L1733.73 197.602L1733.73 156.327z"
          stroke="#1be11d"
          fill="none"
        />
        <path
          d="M2044.91 197.602L2044.91 156.327L1853.54 156.327C 1841.53 156.327 1831.4 166.083 1831.4 178.09L1831.4 178.09L1831.4 411.861C 1831.4 423.869 1841.53 434 1853.54 434L1853.54 434L2044.91 434L2044.91 392.724L1872.67 392.724L1872.67 315.801L2033.65 315.801L2033.65 274.525L1872.67 274.525L1872.67 197.602L2044.91 197.602z"
          stroke="#1be11d"
          fill="none"
        />
        <path
          d="M2273.43 156.327L2186 156.327C 2174 156.327 2164.99 165.707 2164.99 177.34L2164.99 177.34L2164.99 412.987C 2164.99 424.619 2174.37 434 2186.38 434L2186.38 434L2273.43 434C 2351.11 434 2413.4 372.462 2413.4 295.163C 2413.4 217.865 2350.73 156.327 2273.43 156.327zM2273.43 392.724L2206.27 392.724L2206.27 197.602L2273.43 197.602C 2328.22 197.602 2372.12 240.754 2372.12 295.163C 2371.74 349.572 2328.59 392.724 2273.43 392.724z"
          stroke="#1be11d"
          fill="none"
        />
      </g>

      <g strokeWidth="15" transform="translate(0 5)" filter="blur(2px)" opacity="0.5">
        <path
          d="M345.667 302.941L345.667 343.148L377.573 343.148L377.573 359.591C 359.12 383.601 330.121 397.107 298.11 397.107C 241.996 397.107 196.427 350.212 196.427 293.562C 196.427 236.537 241.996 191.893 298.11 191.893C 328.615 191.893 355.354 205.399 374.184 227.158L374.184 227.158L403.936 199.021C 377.95 169.758 339.913 151 298.11 151C 219.023 151 155 213.652 155 293.562C 155 373.097 219.023 438 298.11 438C 347.822 438 391.884 413.99 417.117 374.973C 418.247 373.097 419 370.846 419 368.595L419 368.595L419 302.941L345.667 302.941z"
          stroke="#000"
          fill="none"
        />
        <path
          d="M705.198 434L752.102 434L681.933 334.188C 721.708 324.807 750.601 291.786 750.601 249.009C 750.601 198.353 709.701 156.327 659.044 156.327L659.044 156.327L545.723 156.327C 533.716 156.327 523.584 166.083 523.584 178.09L523.584 178.09L523.584 434L564.86 434L564.86 336.814L637.656 336.814L705.198 434zM564.86 197.602L659.044 197.602C 686.811 197.602 709.326 221.242 709.326 249.009C 709.326 276.402 687.562 298.165 659.044 298.165L659.044 298.165L564.86 298.165L564.86 197.602z"
          stroke="#000"
          fill="none"
        />
        <path
          d="M1085.41 434L971.713 165.332C 968.336 157.077 961.207 152.199 952.576 152.199C 943.946 152.199 936.066 157.077 932.689 165.332L932.689 165.332L817.867 434L864.021 434L952.201 228.372L1039.26 434L1085.41 434z"
          stroke="#000"
          fill="none"
        />
        <path
          d="M1366.24 156.327L1366.24 362.706L1206.76 159.328C 1202.64 154.075 1196.63 151.073 1189.88 151.073C 1177.87 151.073 1168.12 160.829 1168.12 172.837L1168.12 172.837L1168.12 434L1209.39 434L1209.39 228.747L1369.24 429.872C 1372.99 434.751 1379 438.128 1386.13 438.128C 1398.13 438.128 1407.52 428.372 1407.52 416.364L1407.52 416.364L1407.52 156.327L1366.24 156.327z"
          stroke="#000"
          fill="none"
        />
        <path
          d="M1733.73 156.327L1497.33 156.327L1497.33 197.602L1594.89 197.602L1594.89 434L1636.17 434L1636.17 197.602L1733.73 197.602L1733.73 156.327z"
          stroke="#000"
          fill="none"
        />
        <path
          d="M2044.91 197.602L2044.91 156.327L1853.54 156.327C 1841.53 156.327 1831.4 166.083 1831.4 178.09L1831.4 178.09L1831.4 411.861C 1831.4 423.869 1841.53 434 1853.54 434L1853.54 434L2044.91 434L2044.91 392.724L1872.67 392.724L1872.67 315.801L2033.65 315.801L2033.65 274.525L1872.67 274.525L1872.67 197.602L2044.91 197.602z"
          stroke="#000"
          fill="none"
        />
        <path
          d="M2273.43 156.327L2186 156.327C 2174 156.327 2164.99 165.707 2164.99 177.34L2164.99 177.34L2164.99 412.987C 2164.99 424.619 2174.37 434 2186.38 434L2186.38 434L2273.43 434C 2351.11 434 2413.4 372.462 2413.4 295.163C 2413.4 217.865 2350.73 156.327 2273.43 156.327zM2273.43 392.724L2206.27 392.724L2206.27 197.602L2273.43 197.602C 2328.22 197.602 2372.12 240.754 2372.12 295.163C 2371.74 349.572 2328.59 392.724 2273.43 392.724z"
          stroke="#000"
          fill="none"
        />
      </g>
      <g
        strokeWidth="10"
        filter="drop-shadow(0 0 1px #14a511) drop-shadow(0 0 1px #14a511) drop-shadow(0 0 5px #14a511)"
      >
        <path
          d="M345.667 302.941L345.667 343.148L377.573 343.148L377.573 359.591C 359.12 383.601 330.121 397.107 298.11 397.107C 241.996 397.107 196.427 350.212 196.427 293.562C 196.427 236.537 241.996 191.893 298.11 191.893C 328.615 191.893 355.354 205.399 374.184 227.158L374.184 227.158L403.936 199.021C 377.95 169.758 339.913 151 298.11 151C 219.023 151 155 213.652 155 293.562C 155 373.097 219.023 438 298.11 438C 347.822 438 391.884 413.99 417.117 374.973C 418.247 373.097 419 370.846 419 368.595L419 368.595L419 302.941L345.667 302.941z"
          stroke="#FFFFFF"
          fill="none"
        />
        <path
          d="M705.198 434L752.102 434L681.933 334.188C 721.708 324.807 750.601 291.786 750.601 249.009C 750.601 198.353 709.701 156.327 659.044 156.327L659.044 156.327L545.723 156.327C 533.716 156.327 523.584 166.083 523.584 178.09L523.584 178.09L523.584 434L564.86 434L564.86 336.814L637.656 336.814L705.198 434zM564.86 197.602L659.044 197.602C 686.811 197.602 709.326 221.242 709.326 249.009C 709.326 276.402 687.562 298.165 659.044 298.165L659.044 298.165L564.86 298.165L564.86 197.602z"
          stroke="#FFFFFF"
          fill="none"
        />
        <path
          d="M1085.41 434L971.713 165.332C 968.336 157.077 961.207 152.199 952.576 152.199C 943.946 152.199 936.066 157.077 932.689 165.332L932.689 165.332L817.867 434L864.021 434L952.201 228.372L1039.26 434L1085.41 434z"
          stroke="#FFFFFF"
          fill="none"
        />
        <path
          d="M1366.24 156.327L1366.24 362.706L1206.76 159.328C 1202.64 154.075 1196.63 151.073 1189.88 151.073C 1177.87 151.073 1168.12 160.829 1168.12 172.837L1168.12 172.837L1168.12 434L1209.39 434L1209.39 228.747L1369.24 429.872C 1372.99 434.751 1379 438.128 1386.13 438.128C 1398.13 438.128 1407.52 428.372 1407.52 416.364L1407.52 416.364L1407.52 156.327L1366.24 156.327z"
          stroke="#FFFFFF"
          fill="none"
        />
        <path
          d="M1733.73 156.327L1497.33 156.327L1497.33 197.602L1594.89 197.602L1594.89 434L1636.17 434L1636.17 197.602L1733.73 197.602L1733.73 156.327z"
          stroke="#FFFFFF"
          fill="none"
        />
        <path
          d="M2044.91 197.602L2044.91 156.327L1853.54 156.327C 1841.53 156.327 1831.4 166.083 1831.4 178.09L1831.4 178.09L1831.4 411.861C 1831.4 423.869 1841.53 434 1853.54 434L1853.54 434L2044.91 434L2044.91 392.724L1872.67 392.724L1872.67 315.801L2033.65 315.801L2033.65 274.525L1872.67 274.525L1872.67 197.602L2044.91 197.602z"
          stroke="#FFFFFF"
          fill="none"
        />
        <path
          d="M2273.43 156.327L2186 156.327C 2174 156.327 2164.99 165.707 2164.99 177.34L2164.99 177.34L2164.99 412.987C 2164.99 424.619 2174.37 434 2186.38 434L2186.38 434L2273.43 434C 2351.11 434 2413.4 372.462 2413.4 295.163C 2413.4 217.865 2350.73 156.327 2273.43 156.327zM2273.43 392.724L2206.27 392.724L2206.27 197.602L2273.43 197.602C 2328.22 197.602 2372.12 240.754 2372.12 295.163C 2371.74 349.572 2328.59 392.724 2273.43 392.724z"
          stroke="#FFFFFF"
          fill="none"
        />
      </g>
    </g>
  </svg>
)

export default Logo
