import { PureComponent, createContext, useContext, ComponentType, FC } from 'react'

export type Variables = {
  PRIVATE_PAGE_PASS: string
  DISABLE_AFTERPAY: string | boolean
  SHOW_SIGN_UP_MODAL: string | boolean
  PROMOCODE_TO_APPLY_AUTOMATICALLY: string
  CREDIT_ACTION_TYPES: {
    [key: string]: number
  }
}

export const defaultVariables: Variables = {
  PRIVATE_PAGE_PASS: process.env.NEXT_PUBLIC_PRIVATE_PAGE_PASS,
  PROMOCODE_TO_APPLY_AUTOMATICALLY: '',
  SHOW_SIGN_UP_MODAL: 'true',
  DISABLE_AFTERPAY: 'true',
  CREDIT_ACTION_TYPES: {
    sign_up: 5,
    follow_star: 5,
    follow_venue: 5,
    order_placed: 5,
    order_canceled: 5,
    joined_from_referral: 10,
    referral: 10,
    order_placed_reward: 5,
    order_placed_reward_canceled: 5,
    first_order_placed_reward: 3,
  },
}

const Context = createContext<Variables>(defaultVariables)

export const useVariables = () => useContext(Context)

export const VariablesProvider: FC<{ variables: Variables }> = ({ children, variables }) => (
  <Context.Provider value={{ ...defaultVariables, ...variables }}>{children}</Context.Provider>
)

export function withVariables<P extends object>(WrappedComponent: ComponentType<P>) {
  return class extends PureComponent<ComponentType<P> & Variables> {
    render() {
      return (
        <Context.Consumer>
          {(variables) => <WrappedComponent {...(this.props as P)} variables={variables} />}
        </Context.Consumer>
      )
    }
  }
}
