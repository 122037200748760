import { gql } from '@apollo/client'

export const GET_USER_CREDIT_CARDS = gql`
  query UserCreditCards {
    currentUser {
      id
      creditCards {
        id
        cardId
        brand
        last4
        expMonth
        expYear
        funding
        name
      }
    }
  }
`

export const ADDRESS_SETTINGS = gql`
  query AddressSettings {
    currentUser {
      id
      addresses {
        edges {
          node {
            id
            pk
            country
            address
            city
            state
            zipcode
            phone
            status
            email
            fullName
            isBilling
          }
        }
      }
    }
  }
`

export const BILLING_ADDRESS_SETTINGS = gql`
  query BillingAddressSettings {
    currentUser {
      id
      billingAddresses {
        edges {
          node {
            id
            pk
            country
            address
            city
            state
            zipcode
            phone
            status
            email
            fullName
            isBilling
          }
        }
      }
    }
  }
`

export const GET_ORDER_BY_ID = gql`
  query OrderById($id: ID!) {
    order(id: $id) {
      id
      pk
      createdAt
      orderType
      status
      credit
      user {
        id
        type
      }
      promocode {
        id
        type
        code
        amount
      }
      deliveryOption {
        id
        type
      }
      pay {
        id
        paymentIntent {
          id
          paymentMethodName
          wallet
        }
        charge {
          id
          paymentMethodName
        }

        transactionUid
      }
      insurance {
        id
        pk
        status
        totalPrice
        policies {
          id
          pk
          policyId
          status
          cost
          price
        }
      }
      shipping {
        id
        city
        state
        email
        state
        zipCode
        address1
        fullName
        phoneNumber
      }
      serviceFee
      tax
      totalPrice
      shippingPrice
      tevoOrder {
        sellerRejectionReason
        sellerRejectionNotes
        shipping
        shipments {
          state
        }
        items {
          ticketGroup {
            id
            row
            section
            retailPrice
            format
            inHand
            inHandOn
          }
        }
      }
      orderitemSet {
        edges {
          node {
            id
            quantity
            price
            package {
              id
              price
              name
              shortDescription
              longDescription
              deliveryNotes
              image {
                id
                path
                extension
              }
            }

            artwork {
              id
              slug
              title
              eventStartDate
              eventStartDateLocal
              artworkOrTourOrArtistImage {
                id
                extension
                path
              }
              tour {
                id
                title
              }
              venue {
                id
                name
                slug
                city
                state
                timeZone
                venuePhoto {
                  id
                  path
                  extension
                }
              }
              mainArtist {
                fullName
                slug
              }
            }
          }
        }
      }
    }
  }
`

export const GET_ORDER_BY_EMAIL_AND_ID = gql`
  query getOrderByEmailAndId($email: String!, $id: Int!) {
    getOrderByEmailAndId(email: $email, orderId: $id) {
      order {
        id
        pk
        orderType
        createdAt
        status
        totalRefunded
        pay {
          id
          paymentIntent {
            id
            paymentMethodName
          }
          charge {
            id
            paymentMethodName
          }

          transactionUid
        }
        serviceFee
        tax
        totalPrice
        shippingPrice
        tevoOrder {
          sellerRejectionReason
          sellerRejectionNotes
          shipping
          shipments {
            state
          }
          items {
            ticketGroup {
              id
              row
              section
              retailPrice
              format
              inHand
              inHandOn
            }
          }
        }
        orderitemSet {
          edges {
            node {
              id
              quantity
              price
              package {
                id
                name
                shortDescription
                deliveryNotes
                image {
                  id
                  path
                  extension
                }
              }

              artwork {
                id
                slug
                title
                eventStartDateLocal
                venue {
                  id
                  name
                  slug
                }
                mainArtist {
                  fullName
                  slug
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_USER_ACTIVE_CART_DETAILS = gql`
  query GetUserActiveCartDetails {
    findCart {
      pk
      id
      status
      deliveryOption
      createdAt
      items {
        id
        pk
        artwork {
          id
          slug
          title
          subTitle
          artistFullName
          disablePromocodes

          artworkOrTourOrArtistImage {
            id
            extension
            path
          }
          venue {
            id
            city
            name
            state
            country
            timeZone
          }
          eventStartDateLocal
        }
        package {
          id
          pk
          name
          shortDescription
          longDescription
          deliveryNotes
          price
          image {
            path
            extension
          }
        }
        tevoTicketGroup {
          id
          type
          inHandOn
          format
          row
          section
          retailPrice
          quantity
          availableQuantity
        }
        quantity
        price
      }
    }
  }
`

export const USER_BASE_DATA = gql`
  query UserBaseData {
    currentUser {
      pk
      id
      email
      type
      lastName
      firstName
      fullName
      phoneNumber
      isPhoneNumberVerified
      credit
    }
  }
`

export const GET_EVENTS = gql`
  query GetEvents(
    $featuredDateIsNull: Boolean
    $announceDateGte: DateTime
    $eventStartDateGte: DateTime
    $eventStartDateLte: DateTime
    $eventStartDateLocalGte: DateTime
    $eventStartDateLocalLte: DateTime
    $venueCoordinates: String
    $tourSlug: String
    $starSlug: String
    $orderBy: String
    $salesAs: String
    $statusIn: String
    $status: String
    $after: String
    $first: Int
  ) {
    allArtworks(
      featuredDate_Isnull: $featuredDateIsNull
      announceDate_Gte: $announceDateGte
      eventStartDate_Gte: $eventStartDateGte
      eventStartDate_Lte: $eventStartDateLte
      eventStartDateLocal_Gte: $eventStartDateLocalGte
      eventStartDateLocal_Lte: $eventStartDateLocalLte
      venueCoordinates: $venueCoordinates
      artists_Slug: $starSlug
      tour_Slug: $tourSlug
      orderBy: $orderBy
      salesAs: $salesAs
      status_In: $statusIn
      status: $status
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          title
          slug
          eventStartDateLocal
          eventStartDate
          artworkOrTourOrArtistImage {
            id
            path
            extension
          }
          venue {
            id
            city
            state
            name
            venuePhoto {
              id
              path
              extension
            }
          }
          tevoId
          hasPackages
          externalLink
          externalVipLink
        }
      }

      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export const GRANTED_WEB_SETTINGS = gql`
  query GrantedWebSettings($name: String, $type: String) {
    grantedWebSettings(name: $name, type: $type) {
      edges {
        node {
          pk
          id
          name
          type
          settings
          sections {
            pk
            id
            name
            type
            settings
          }
        }
      }
    }
  }
`

export const GRANTED_PACKAGES = gql`
  query GrantedPackages($artworkSlug: String!, $isTicket: Boolean!) {
    allPackages(artworks_Slug: $artworkSlug, isTicket: $isTicket, status: "public") {
      edges {
        node {
          id
          pk
          name
          price
          quantity

          longDescription
          shortDescription

          deliveryNotes

          minSplit
          maxSplit

          availableQuantity

          image {
            id
            path
            extension
          }

          deliveryOptions {
            edges {
              node {
                id
                type
              }
            }
          }
        }
      }
    }
  }
`

export const TEVO_TICKETS = gql`
  query tevoTickets($artworkPk: Int!, $tevoTicketsLimit: Int, $tevoTicketsOffset: Int) {
    findArtwork(pk: $artworkPk) {
      id

      tevoTicketGroups(limit: $tevoTicketsLimit, offset: $tevoTicketsOffset) {
        id
        section
        retailPrice
        type
        quantity
        row
        availableQuantity
        publicNotes
        splits
        format
        tevoSectionName
        publicNotes

        discount {
          discount
          discountedPrice
          type
          expiresOn
        }
      }

      tevoSeatingChartConfiguration {
        id
      }
    }
  }
`

export const GRANTED_WEB_SECTION = gql`
  query GrantedWebSection($name: String!) {
    grantedWebSection(name: $name) {
      id
      name
      type
      settings
    }
  }
`

export const GRANTED_WEB_SECTIONS = gql`
  query GrantedWebSections($name: String, $type: String) {
    grantedWebSections(name: $name, type: $type) {
      edges {
        node {
          id
          name
          settings
        }
      }
    }
  }
`

export const GRANTED_SEARCH = gql`
  query GrantedSearch($query: String!, $types: [String!]!) {
    grantedSearch(query: $query, types: $types) {
      stars {
        slug
        imagePath
        fullName
      }
      venues {
        slug
        name
        imagePath
      }
      events {
        slug
        title
        eventStartDateLocal
        city
        state
      }
      tours {
        title
        slug
        imagePath
      }
    }
  }
`

export const GET_ALL_CATEGORIES_QUERY = gql`
  query AllSubCategoriesForStars($categoryId: ID!) {
    allCategories: childCategoriesWithRelations(
      relationWith: ["artist"]
      isSearchable: true
      categoryId: $categoryId
    ) {
      edges {
        node {
          id
          name
          position
          starcategorySet(isSearchable: true) {
            edges {
              node {
                id
                name
                position
                starcategorySet(isSearchable: true) {
                  edges {
                    node {
                      id
                      name
                      position
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const ALL_CATEGORIES = gql`
  query AllCategories(
    $useConcert: Boolean!
    $useComedy: Boolean!
    $useSport: Boolean!
    $concertCategoryId: ID!
    $comedyCategoryId: ID!
    $sportCategoryId: ID!
  ) {
    concerts: childCategoriesWithRelations(
      categoryId: $concertCategoryId
      relationWith: ["artist"]
      isSearchable: true
    ) @include(if: $useConcert) {
      edges {
        node {
          id
          isSearchable
          name
          starcategorySet(isSearchable: true) {
            edges {
              node {
                id
                name
                starcategorySet(isSearchable: true) {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
          image {
            id
            path
            extension
          }
          position
          parent {
            id
            name
          }
        }
      }
    }
    sports: childCategoriesWithRelations(
      categoryId: $sportCategoryId
      relationWith: ["artist"]
      isSearchable: true
    ) @include(if: $useSport) {
      edges {
        node {
          id
          isSearchable
          name
          starcategorySet(isSearchable: true) {
            edges {
              node {
                id
                name
                starcategorySet(isSearchable: true) {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
          image {
            id
            path
            extension
          }
          position
          parent {
            id
            name
          }
        }
      }
    }
    comedy: childCategoriesWithRelations(
      categoryId: $comedyCategoryId
      relationWith: ["artist"]
      isSearchable: true
    ) @include(if: $useComedy) {
      edges {
        node {
          id
          isSearchable
          name
          starcategorySet(isSearchable: true) {
            edges {
              node {
                id
                name
                starcategorySet(isSearchable: true) {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
          image {
            id
            path
            extension
          }
          position
          parent {
            id
            name
          }
        }
      }
    }
  }
`

export const GET_USER_ACTIVE_SHORT_CART = gql`
  query GetUserActiveShortCart {
    findCart {
      pk
      id
    }
  }
`

export const GET_EVENT_AUCTION_BY_ID = gql`
  query GetEventAuctionById($pk: Int!) {
    findArtwork(pk: $pk) {
      # auction and experiences
      id
      pk
      slug
      title
      status
      origin
      salesAs
      description
      eventStartDate
      eventStartDateLocal
      # experiences only
      tevoId
      artistFullName
      salesOnDateLocal
      eventExtendedStatus
      venue {
        # auction and experiences
        id
        zip
        name
        city
        state
        tevoId
        timeZone
        streetAddress
      }
      images {
        # auction and experiences
        edges {
          node {
            id
            path
            extension
          }
        }
      }
      mainArtist {
        # auction and experiences
        fullName
        meta
        slug
      }
      tour {
        id
        title
      }
      artworkOrTourOrArtistImage {
        # auction and experiences
        path
        extension
      }
      packageInfo(status: "public") {
        # experiences only
        id
        pk
        isTicket
      }
    }
  }
`

export const GET_CITIES = gql`
  query GetCities(
    $id: ID
    $first: Int
    $after: String
    $orderBy: String
    $coordinates: String
    $name: String
    $status: String
    $coordinatesSort: String
  ) {
    allCities(
      id: $id
      first: $first
      after: $after
      orderBy: $orderBy
      coordinates: $coordinates
      name_Icontains: $name
      status: $status
      coordinatesSort: $coordinatesSort
    ) {
      edges {
        node {
          id
          pk
          name
          stateCode
          lat
          lon
        }
      }
    }
  }
`

export const STARS_IN_CATEGORY_EXTENDED_QUERY = gql`
  query artistsFromCategoriesExtended(
    $first: Int
    $orderBy: String
    $status: String
    $after: String
    $activeArtworks: Boolean
    $venueCoordinates: String
    $categoryWithChild_Exact: String
    $annotateArtworkMinStartDate: Boolean
  ) {
    allArtists(
      first: $first
      after: $after
      status: $status
      orderBy: $orderBy
      venueCoordinates: $venueCoordinates
      categoryWithChild_Exact: $categoryWithChild_Exact
      activeArtworks: $activeArtworks
      annotateArtworkMinStartDate: $annotateArtworkMinStartDate
    ) {
      edges {
        node {
          id
          fullName
          file {
            extension
            path
          }
          slug
          categories {
            edges {
              node {
                id
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export const STARS_WITH_VIP_QUERY = gql`
  query artistsWithTicketsVIP(
    $first: Int
    $orderBy: String
    $status: String
    $after: String
    $ids: String
    $activeArtworks: Boolean
  ) {
    allArtists(
      status: $status
      first: $first
      after: $after
      ids: $ids
      activeArtworks: $activeArtworks
      hasPackages: true
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          fullName
          slug

          featuredDate
          file {
            extension
            path
          }
        }
      }

      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export const IS_USER_EXIST = gql`
  query IsUserExists($email: String!) {
    isUserExists(email: $email)
  }
`

export const GET_AUTOMATIC_APPLY_PROMOCODE = gql`
  query getAutomaticApplyPromocode {
    automaticApplyPromocode {
      id
      code
    }
  }
`

export const GET_USER_REFERRAL_CODE = gql`
  query GetUserReferralCode {
    currentUser {
      id
      redemptionsCount
      referral {
        id
        code
      }
    }
  }
`

export const GET_USER_REDEMPTIONS = gql`
  query GetUserRedemptions($first: Int, $after: String) {
    currentUser {
      id
      redemptionsCount
      redemptions(orderBy: "-created", first: $first, after: $after) {
        edges {
          node {
            id
            fullName
          }
        }
      }
    }
  }
`

export const FIND_USER_REFERRAL = gql`
  query UserReferral($code: String!) {
    findUserReferral(code: $code) {
      id
    }
  }
`

export const GET_CREDIT_ACTION_TYPES = gql`
  query GetCreditActionTypes($id: ID) {
    creditActionTypes(id: $id) {
      edges {
        node {
          id
          pk
          type
          amount
          createdAt
          updatedAt
        }
      }
    }
  }
`
