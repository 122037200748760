import { gql, useMutation } from '@apollo/client'

import { AddOrUpdateContactListMutation, AddOrUpdateContactListMutationVariables } from 'gql'

export const ADD_OR_UPDATE_CONTACT_LIST = gql`
  mutation AddOrUpdateContactList($listNames: [String!], $listIds: [String!], $data: ContactData!) {
    addOrUpdateContactList(input: { listNames: $listNames, listIds: $listIds, data: [$data] }) {
      ... on ContactMutationSuccess {
        success
      }
      ... on ContactMutationFailure {
        errorMessage
        fieldsErrors {
          field
          messages
        }
      }
    }
  }
`

const useEmailSubsribeContactList = () =>
  useMutation<AddOrUpdateContactListMutation, AddOrUpdateContactListMutationVariables>(
    ADD_OR_UPDATE_CONTACT_LIST
  )

export default useEmailSubsribeContactList
