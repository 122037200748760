export const DEFAULT_BREAKPOINTS = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
}

export const DESKTOP_DEVICE = 'desktop'
export const MOBILE_DEVICE = 'mobile'
export const TABLET_DEVICE = 'tablet'
