import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useLazyQuery } from '@apollo/client'

import { useBrowserLocation } from 'hooks'
import { GET_CITIES } from 'gql/queries'
import { GetCitiesQuery, GetCitiesQueryVariables } from 'gql'
import { useRouter } from 'next/router'

export type Location = {
  lat?: number
  lon?: number
  name?: string
  id?: string
  stateCode?: string
}

export type Coordinates = {
  latitude: number
  longitude: number
}

type ScreenClassContext = {
  loading: boolean
  locationLoading: boolean
  location?: Location
  coordinates?: Coordinates
  browserCoordinates?: Coordinates
  setLocation: (v: Location) => void
}

const Context = createContext<ScreenClassContext>({} as ScreenClassContext)

export const useStore = () => useContext(Context)

const Provider = ({ children }) => {
  const { coordinates, status } = useBrowserLocation()
  const [location, setLocation] = useState<Location>()
  const { query, isReady } = useRouter()

  const [getCity, { loading }] = useLazyQuery<GetCitiesQuery, GetCitiesQueryVariables>(GET_CITIES)

  const getCityFromArray = (data: GetCitiesQuery) => data.allCities?.edges[0]?.node

  useEffect(() => {
    if (!isReady || location) return

    if (query.cityId) {
      getCity({ variables: { id: query.cityId as string } }).then(({ data }) => {
        setLocation(getCityFromArray(data))
      })

      return
    }

    if (status !== 'success') return

    getCity({
      variables: {
        coordinatesSort: coordinates ? `${coordinates.latitude},${coordinates.longitude}` : null,
        first: 1,
      },
    }).then(({ data }) => {
      setLocation(getCityFromArray(data))
    })
  }, [coordinates, getCity, isReady, location, query.cityId, status])

  const providerValue = useMemo(
    () => ({
      loading,
      setLocation,
      location,
      coordinates: location
        ? {
            latitude: location.lat,
            longitude: location.lon,
          }
        : null,
      browserCoordinates: coordinates,
      locationLoading: status === 'waiting',
    }),
    [loading, setLocation, location, coordinates, status]
  )

  return <Context.Provider value={providerValue}>{children}</Context.Provider>
}
export default Provider
