import classnames from 'classnames'
import { FC } from 'react'

import styles from './Heading.module.scss'

type HeadingVariants = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span'

type HeadingProps = {
  Tag: HeadingVariants
  className?: string
  center?: boolean
  uppercase?: boolean
  color?: GThemeColorsNew
  colorGlow?: GThemeColorsNew
  size?: HeadingVariants
  fontWeight?: GThemeFontWeights
}

const Heading: FC<HeadingProps> = ({
  Tag,
  size,
  colorGlow,
  center,
  uppercase,
  className,
  fontWeight,
  color = 'g-white',
  children,
}) => (
  <Tag
    className={classnames(
      [styles.heading],
      [styles[color]],
      styles[size],
      styles[`w${fontWeight}`],
      {
        [styles[`glow-${colorGlow}`]]: colorGlow,
        [styles.uppercase]: uppercase,
        [styles.center]: center,
      },
      className
    )}
  >
    {children}
  </Tag>
)

export default Heading
