import NextNprogress from 'nextjs-progressbar'
import { ApolloProvider } from '@apollo/client'
import NextApp, { AppContext } from 'next/app'
import { useRouter } from 'next/router'
import { parseCookies } from 'nookies'
import { useEffect, useMemo, useRef, useState } from 'react'
import { VariablesProvider, withVariables } from 'hooks/useVariables'

import ResponsiveContext from 'components/contexts/responsive'
import GeolocationContext from 'components/contexts/geolocation'
import { getSSRScreenClass, isInFrame as isInFrameFunc } from 'utils'
import {
  EVENT,
  LOGIN,
  CHECKOUT,
  ACCOUNT_BIDS,
  ACCOUNT_ORDERS,
  ACCOUNT_FOLLOWS,
  ACCOUNT_SETTINGS,
  JOIN,
} from 'routes/paths'
import { LOCAL_STORAGE_PROMOCODES } from 'constants/constant'
import {
  GetCreditActionTypesQuery,
  GetCreditActionTypesQueryVariables,
  GrantedWebSectionQuery,
  GrantedWebSectionQueryVariables,
  GrantedWebSettingsQuery,
  GrantedWebSettingsQueryVariables,
} from 'gql'

import withFirewall from 'components/HOCs/withFirewall'
import { GET_CREDIT_ACTION_TYPES, GRANTED_WEB_SECTION, GRANTED_WEB_SETTINGS } from 'gql/queries'
import { Notification } from '~shared'
import { initializeApollo, useApollo } from '~services/apollo'
import SubscribePromocodeModal from '~shared/SubscribePromocodeModal'
import { useUtmHistory } from '~hooks'

import 'rc-dropdown/assets/index.css'
import 'styles/globals.scss'

const SIGN_UP_MODAL_EXCLUDE_ROUTES = [JOIN, LOGIN]

const App = ({
  Component,
  pageProps,
  screenClass,
  variables,
  signUpModalData,
  authCookieExists,
}) => {
  const apolloClient = useApollo(pageProps)
  const { pathname } = useRouter()
  const { setUtm } = useUtmHistory()
  const [showPromocodeModal, setShowPromocodeModal] = useState(false)

  useEffect(() => {
    if (typeof window !== undefined && 'zE' in window) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.zE('webWidget', EVENT.pathname === pathname ? 'hide' : 'show')
    }
  }, [pathname])

  const timeoutRef = useRef<NodeJS.Timeout>()

  const signUpModalSettings = JSON.parse(signUpModalData?.settings || '{}')

  const isInFrame = isInFrameFunc()

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!isInFrame) {
      if (
        localStorage.getItem(LOCAL_STORAGE_PROMOCODES) !== 'true' &&
        !SIGN_UP_MODAL_EXCLUDE_ROUTES.includes(pathname) &&
        variables.SHOW_SIGN_UP_MODAL === 'true' &&
        !authCookieExists
      ) {
        if (signUpModalSettings.delayInMilliseconds) {
          const delay = parseInt(signUpModalSettings.delayInMilliseconds, 10)

          timeoutRef.current = setTimeout(() => {
            setShowPromocodeModal(true)
          }, delay)

          return () => {
            clearTimeout(timeoutRef.current)
          }
          // eslint-disable-next-line no-else-return
        } else {
          setShowPromocodeModal(true)
        }
      }
    }
  }, [pathname])

  useEffect(() => {
    if (!isInFrame) {
      setUtm()
    }
  }, [isInFrame])

  const WrappedComponent = useMemo(
    () => (isInFrame ? withVariables(Component) : withVariables(withFirewall(Component))),
    [Component, isInFrame]
  )

  if (isInFrame) {
    return (
      <>
        <VariablesProvider variables={variables}>
          <ApolloProvider client={apolloClient}>
            <ResponsiveContext screenClassSSR={screenClass}>
              <WrappedComponent {...pageProps} isPrivate={pageProps.isPrivate} />
            </ResponsiveContext>
          </ApolloProvider>
        </VariablesProvider>
      </>
    )
  }

  return (
    <>
      <NextNprogress color="#73ff73" height={3} options={{ showSpinner: false }} />
      <Notification />
      <VariablesProvider variables={variables}>
        <ApolloProvider client={apolloClient}>
          <GeolocationContext>
            <ResponsiveContext screenClassSSR={screenClass}>
              <WrappedComponent {...pageProps} isPrivate={pageProps.isPrivate} />
              <SubscribePromocodeModal
                visible={showPromocodeModal}
                onClose={() => {
                  setShowPromocodeModal(false)
                  localStorage.setItem(LOCAL_STORAGE_PROMOCODES, JSON.stringify(true))
                }}
                onSuccess={() =>
                  localStorage.setItem(LOCAL_STORAGE_PROMOCODES, JSON.stringify(true))
                }
                settings={signUpModalSettings}
              />
            </ResponsiveContext>
          </GeolocationContext>
        </ApolloProvider>
      </VariablesProvider>
    </>
  )
}

App.getInitialProps = async (context: AppContext) => {
  const initialProps = await NextApp.getInitialProps(context)

  const cookies = parseCookies(context.ctx)

  const screenClass = cookies.screenClass || getSSRScreenClass(context.ctx.req)

  if (
    !cookies.escher_sess &&
    [CHECKOUT, ACCOUNT_BIDS, ACCOUNT_ORDERS, ACCOUNT_FOLLOWS, ACCOUNT_SETTINGS].includes(
      context.router.asPath
    )
  ) {
    const { res } = context.ctx

    res?.writeHead(301, {
      Location: LOGIN,
    })
    res?.end()
  }

  const apolloClient = initializeApollo()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let variables: any = {}

  try {
    const { data } = await apolloClient.query<
      GrantedWebSettingsQuery,
      GrantedWebSettingsQueryVariables
    >({
      query: GRANTED_WEB_SETTINGS,
      variables: {
        name: 'variables',
      },
    })

    variables = JSON.parse(data.grantedWebSettings.edges[0].node.settings)

    const { data: credits } = await apolloClient.query<
      GetCreditActionTypesQuery,
      GetCreditActionTypesQueryVariables
    >({
      query: GET_CREDIT_ACTION_TYPES,
    })

    variables.CREDIT_ACTION_TYPES = credits?.creditActionTypes.edges.reduce(
      (prev, cur) => ({ ...prev, [cur?.node.type]: cur?.node.amount }),
      {}
    )
  } catch {
    variables = {}
  }

  let signUpModalData: GrantedWebSectionQuery['grantedWebSection']

  try {
    const { data } = await apolloClient.query<
      GrantedWebSectionQuery,
      GrantedWebSectionQueryVariables
    >({
      variables: { name: 'sign-up-modal' },
      query: GRANTED_WEB_SECTION,
    })

    signUpModalData = data.grantedWebSection
  } catch {
    signUpModalData = undefined
  }

  return {
    ...initialProps,
    screenClass,
    variables,
    signUpModalData,
    authCookieExists: !!cookies.escher_sess,
  }
}

export default App
