import { ApolloClient, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { relayStylePagination, offsetLimitPagination } from '@apollo/client/utilities'
import { useMemo } from 'react'
import { isEqual } from 'lodash'
import deepmerge from 'deepmerge'

type PageProps = {
  props: {
    // eslint-disable-next-line
    [key: string]: any
  }
  // eslint-disable-next-line
  [key: string]: any
}

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

// eslint-disable-next-line import/no-mutable-exports
export let apolloClient: ApolloClient<NormalizedCacheObject>

export const GRAPHQL_HOST = process.env.NEXT_PUBLIC_API_HOST

export const createApolloClient = () =>
  new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: new HttpLink({
      uri: GRAPHQL_HOST,
    }),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            allArtworks: relayStylePagination([
              'salesAs',
              'venue_Slug',
              'featuredDate_Isnull',
              'title_Icontains',
              'artists_Slug',
              'venueCoordinates',
              'tour_Slug',
              'ids',
              'eventStartDate_Gte',
              'eventStartDate_Lte',
              'eventStartDateLocal_Gte',
              'eventStartDateLocal_Lte',
            ]),
            allArtists: relayStylePagination([
              'categories_Contains',
              'firstOrLastName_Istartswith',
              'categoryWithChild_Exact',
              'hasPackages',
            ]),
            allVenues: relayStylePagination(),
          },
        },
        ArtistNode: {
          fields: {
            artworks: relayStylePagination(),
          },
        },
        UserNode: {
          fields: {
            creditHistory: relayStylePagination(),
          },
        },
        ArtworkNode: {
          fields: {
            tevoTicketGroups: offsetLimitPagination(['pk', 'id', 'tevoSectionNames']),
          },
        },
      },
    }),
  })

export const initializeApollo = (initialState = null) => {
  // eslint-disable-next-line no-underscore-dangle
  const _apolloClient = apolloClient ?? createApolloClient()

  if (initialState) {
    const existingCache = _apolloClient.extract()

    const data = deepmerge(initialState, existingCache, {
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    })

    _apolloClient.cache.restore(data)
  }

  if (typeof window === 'undefined') return _apolloClient

  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export const addApolloState = (
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: PageProps
) => {
  const pagePropsCopy = pageProps

  if (pagePropsCopy?.props) {
    pagePropsCopy.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pagePropsCopy
}

export const useApollo = (pageProps: PageProps) => {
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  const store = useMemo(() => initializeApollo(state), [state])
  return store
}
