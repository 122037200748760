const Sentry = require('@sentry/nextjs')
const { gql } = require('@apollo/client')
const { createApolloClient } = require('~services/apollo')

const setCurrentUserToSentry = async () => {
  const client = createApolloClient()
  const { data } = await client.query({
    query: gql`
      {
        currentUser {
          id
          email
        }
      }
    `,
  })
  if (data && data.currentUser) {
    Sentry.setUser({ id: data.currentUser.id, email: data.currentUser.email })
  }
}

(async () => {
  if (!process.env.NEXT_PUBLIC_IS_SENTRY) return
  Sentry.init({
    dsn: 'https://4b3fb421ac574b269ca435f5055a0213@o529742.ingest.sentry.io/5648574',
    environment: process.env.NODE_ENV,
    ignoreErrors: [
      // Error generated by a bug in auto-fill library from browser
      // https://github.com/getsentry/sentry/issues/5267
      /Blocked a frame with origin/,
      // Inner https://github.com/ticketevolution/seatmaps-client issue
      /Seatmaps must be initialized with a DOM element./,
      // https://github.com/getsentry/sentry/issues/12676
      /A network error occurred./,
      /The network connection was lost./,
      // errors those occurs when API not working
      "SyntaxError: Unexpected token '<'",
      "Error: JSON Parse error: Unrecognized token '<'",
      'Unexpected token < in JSON at position 0',
      // https://stackoverflow.com/questions/55738408/javascript-typeerror-cancelled-error-when-calling-fetch-on-ios
      /Failed to fetch/,
      /NetworkError when attempting to fetch resource./,
      'Error: Load failed',
      'TypeError: Cancelled',
      'TypeError: Type error',
      'Response not successful: Received status code 400',
      // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/14
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      // https://developers.facebook.com/community/threads/320013549791141/,
      "Can't find variable: _AutofillCallbackHandler",
      /Matrix is not invertible/,
    ],
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  })
  await setCurrentUserToSentry()
})()
