/* eslint-disable jsx-a11y/click-events-have-key-events */
import classnames from 'classnames'
import { FC, ButtonHTMLAttributes, AnchorHTMLAttributes, KeyboardEvent } from 'react'
import Link, { LinkProps } from 'next/link'

import { IconTypes } from '~ui/Icon'
import { Icon } from '~ui' // icon is substituted

import styles from './Button.module.scss'

export type Variants = 'primary' | 'secondary'

export type ButtonProps = {
  variant?: Variants
  icon?: IconTypes
  uppercase?: boolean
  loading?: boolean
  fluid?: boolean
  iconLeft?: boolean
  iconSize?: string | number
  iconGlow?: boolean
  danger?: boolean
  clr?: GThemeColorsNew
  bodyClr?: GThemeColorsNew
  fontClr?: GThemeColorsNew
  defaultGlow?: GThemeColorsNew
  className?: string
  onClick?: ButtonClickEventT
  big?: boolean
} & (
  | ({ mode?: 'external-link' } & AnchorHTMLAttributes<HTMLAnchorElement>)
  | ({ mode?: 'button' } & ButtonHTMLAttributes<HTMLButtonElement>)
  | ({ mode?: 'link' } & LinkProps & {
        onKeyDown?: (e: KeyboardEvent<HTMLAnchorElement>) => void
      })
)

const Button: FC<ButtonProps> = ({
  icon,
  loading,
  fluid,
  iconLeft,
  iconSize = '1em',
  iconGlow = false,
  variant = 'secondary',
  uppercase = true,
  children,
  danger,
  className,
  clr = 'light-purple',
  mode,
  big,
  bodyClr,
  fontClr,
  defaultGlow,
  onClick,
  ...props
}) => {
  const classNames = classnames(styles.button, {
    [styles[variant]]: variant,
    [styles.loading]: loading,
    [styles['button-icon']]: icon && !children,
    [styles['button-text-and-icon']]: icon && children,
    [styles['button-fluid']]: fluid,
    [styles['icon-left']]: iconLeft,
    [styles[`body-${bodyClr}`]]: bodyClr,
    [styles.uppercase]: uppercase,
    [styles.red]: danger,
    [styles.big]: big,
    [styles[clr]]: clr,
    [styles[`${variant}-default-${defaultGlow}`]]: defaultGlow,
    [className]: className,
    [styles[`font-${fontClr}`]]: fontClr,
  })

  const IconJSX = icon && (
    <Icon
      name={icon}
      width={iconSize}
      height={iconSize}
      glowColor={clr}
      glow={iconGlow}
      fill="currentColor"
    />
  )

  if (mode === 'external-link') {
    return (
      <a
        className={classNames}
        target="_blank"
        rel="noreferrer"
        role="link"
        tabIndex={0}
        onClick={onClick}
        {...(props as AnchorHTMLAttributes<HTMLAnchorElement>)}
      >
        {children}
        {IconJSX}
      </a>
    )
  }

  if (mode === 'link') {
    return (
      <Link {...(props as LinkProps)}>
        <a role="link" className={classNames} onClick={onClick} tabIndex={0}>
          <span>{children}</span>
          {IconJSX}
        </a>
      </Link>
    )
  }

  return (
    <button
      type="button"
      className={classNames}
      onClick={onClick}
      {...(props as ButtonHTMLAttributes<HTMLButtonElement>)}
    >
      {children}
      {IconJSX}
    </button>
  )
}

export { styles }

export default Button
