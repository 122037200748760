export const HOME = '/'

export const EXPERIENCES = '/experiences'

export const EVENT = (slug: string) => `${EXPERIENCES}/${slug}`
EVENT.pathname = `${EXPERIENCES}/[slug]`
export const EVENT_VIP = (slug: string) => `${EXPERIENCES}/${slug}?vip=true`

export const EXPERIENCES_BUYS = `${EXPERIENCES}?salesAs=buy_it_now_option`
export const EXPERIENCES_AUCTIONS = `${EXPERIENCES}?salesAs=auction`

export const CHECKOUT = '/checkout'
export const CHECKOUT_CONFIRMATION_RELATIVE = ({ orderId }: { orderId: string }) =>
  `/checkout-confirm?orderId=${orderId}`
export const CHECKOUT_CONFIRMATION = ({ orderId }: { orderId: string }) =>
  typeof window !== 'undefined'
    ? `${window.location.origin}/checkout-confirm?orderId=${orderId}`
    : ''
export const CHECKOUT_TRACKING = ({ orderId }: { orderId: string }) =>
  typeof window !== 'undefined'
    ? `${window.location.origin}/checkout-tracking?orderId=${orderId}`
    : ''

export const CHECKOUT_OFFER = (offerId: string) => `${CHECKOUT}/${offerId}`
CHECKOUT_OFFER.pathname = `${CHECKOUT}/[offerId]`

export const LOGIN = '/login'
export const JOIN = '/join'
export const LOGOUT = '/logout'
export const FORGOT_PASSWORD = '/forgot-password'
export const RESET_PASSWORD = '/reset-password'
export const ABOUT = '/about'
export const FAN_PROMISE = '/fan-promise'
export const ORDER_STATUS = '/order-status'

export const TOURS = '/tours'
export const TOUR = (slug: string) => `${TOURS}/${slug}`
TOUR.pathname = `${TOURS}/[slug]`

export const STARS = '/stars'
export const STAR = (slug: string) => `${STARS}/${slug}`
STAR.pathname = `${STARS}/[slug]`

export const ACCOUNT = '/account'
export const ACCOUNT_SETTINGS = '/account/settings'
export const ACCOUNT_ORDERS = '/account/orders'
export const ACCOUNT_BIDS = '/account/bids'
export const ACCOUNT_FOLLOWS = '/account/follows'
export const GRANTED_REWARDS = '/account/rewards'

export const VENUES = '/venues'
export const VENUE = (slug: string) => `${VENUES}/${slug}`
VENUE.pathname = `${VENUE}/[slug]`

export const SEARCH_RESULTS = '/search'
export const CONCERTS = '/concerts'
export const COMEDY = '/comedy'
export const SPORTS = '/sports'

export const AUCTIONS = '/auctions'
export const VIP_TICKETS = '/vip-tickets'
export const HIDEAWAY = '/hideaway'

export const MARKETING = (slug: string) => `/${slug}`

export const CONFIRM_BID = '/confirm-bid'
export const REWARDS = '/rewards'
export const REWARDS_JOIN = '/rewards-join'
