import { FC } from 'react'
import { PRIVACY_POLICY, TERMS_AND_CONDITIONS } from 'routes/external'
import { Typography, Link } from '~ui'

const { Text } = Typography

const DEFAULT_MESSAGE = 'By purchasing tickets, you agree to Granted’s'

const Agreement: FC<{
  message?: string
  linkColor?: GThemeColorsNew
}> = ({ linkColor, message = DEFAULT_MESSAGE }) => (
  <Text color="g-gray-1" size="sm">
    {message}{' '}
    <Link size="sm" href={TERMS_AND_CONDITIONS} target="_blank" rel="noopener" color={linkColor}>
      Terms of Service
    </Link>
    {' and '}
    <Link size="sm" href={PRIVACY_POLICY} target="_blank" rel="noopener" color={linkColor}>
      Privacy Policy
    </Link>
    .
  </Text>
)

export default Agreement
