import { string } from 'yup'

export const ZIP_CODE = string()
  .when('country', {
    is: (country: string) => country === 'US',
    then: string().matches(/^[0-9]{5}(?:-[0-9]{4})?$/, {
      message: 'Postal Code should have formax XXXXX or XXXXX-XXXX',
    }),
    otherwise: string(),
  })
  .required('Postal Code is required!')

export const EMAIL = (message?: string) =>
  string()
    .test((value, ctx) =>
      value?.endsWith('.con') ? ctx.createError({ message: 'Did you misspell .com?' }) : true
    )
    .email(message || 'Please enter a valid email address!')
    .required('Email is required!')
