import { useRouter } from 'next/router'

import { hasKeys, removeEmptyKeys } from '~utils'
import { useLocalStorage } from '~hooks'

const STORAGE_KEY = 'utm_history'

const UTM_KEYS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']

const useUtmHistory = () => {
  const { query } = useRouter()

  const [utm, set] = useLocalStorage(STORAGE_KEY, [])

  const setUtm = () => {
    const filteredQuery = removeEmptyKeys(query, {
      filterKeys: ['slug'],
    })

    if (hasKeys(query, UTM_KEYS)) {
      set([...utm, filteredQuery])
    }
  }

  return {
    utm,
    setUtm,
  }
}

export default useUtmHistory
