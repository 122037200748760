import { bool, object } from 'yup'
import { useFormik } from 'formik'
import { FC } from 'react'

import useEmailSubsribeContactList from 'hooks/useEmailSubsribeContactList'

import { LIST_SENDGRID_NAMES } from 'constants/constant'
import { EMAIL } from 'constants/schema'
import { Button, Input, Typography, Checkbox, Grid, Logo, Agreement, Heading } from '~ui'
import { Modal } from '~shared'
import { useDataLayer, useUser } from '~hooks'
import { notify } from '~shared/Notification'
import { copyToClipboard } from '~utils'

import styles from './SubscribePromocodeModal.module.scss'

const { Text } = Typography

interface Settings {
  title: string
  description: string
  buttonLabel: string
  promocode: string
  withLogo: string
}

interface SubscribeModalProps {
  onClose: () => void
  visible: boolean
  onSuccess?: () => void
  showOnlyPromocode?: boolean
  settings: Settings
}

const SubscribePromocodeModal: FC<SubscribeModalProps> = ({
  onClose,
  onSuccess,
  visible,
  showOnlyPromocode,
  settings,
}) => {
  const dl = useDataLayer()
  const [user] = useUser()
  const [subscribe, { data, loading }] = useEmailSubsribeContactList()
  const { errors, handleSubmit, values, handleBlur, touched, handleChange } = useFormik({
    initialValues: {
      terms: true,
      email: '',
    },
    onSubmit: ({ email }) => {
      subscribe({
        variables: {
          data: { email },
          listNames: [
            LIST_SENDGRID_NAMES.popup_sign_up,
            LIST_SENDGRID_NAMES.general_welcome_series,
          ],
        },
      }).then(({ data: { addOrUpdateContactList } }) => {
        if (addOrUpdateContactList.__typename === 'ContactMutationSuccess') {
          dl.push({ event: 'signup_popup_submission' })
          onSuccess?.()
        }
      })
    },
    validationSchema: object({
      email: EMAIL(),
      terms: bool().oneOf([true], 'You must accept the Terms and Conditions.').required(),
    }).required(),
  })

  return (
    <Modal
      isOpen={visible && (!user || user.type === 'GUEST')}
      onClose={onClose}
      modalClassName={styles.modal}
    >
      {settings.withLogo === 'true' && (
        <Grid padding="20px 0">
          <Logo />
        </Grid>
      )}
      {data?.addOrUpdateContactList.__typename === 'ContactMutationSuccess' || showOnlyPromocode ? (
        <Grid gap={24}>
          <div>
            <Heading Tag="h4" fontWeight={400} uppercase>
              Thank you and enjoy!
            </Heading>

            <Text color="light-gray" size="sm">
              Copy the code below and paste it at check out for 10% off your next show.
            </Text>
          </div>

          <Input
            value={settings.promocode}
            withError={false}
            onCopy={() => dl.push({ event: 'signup_popup_copy' })}
            iconClickHandler={() => {
              copyToClipboard(settings.promocode)
              notify('Promocode was copied')
              dl.push({ event: 'signup_popup_copy' })
            }}
            iconWithHandlerName="copy"
          />

          <Button uppercase variant="primary" onClick={onClose}>
            continue shopping
          </Button>
        </Grid>
      ) : (
        <Grid>
          <Heading Tag="h4" fontWeight={400} uppercase>
            {settings.title}
          </Heading>
          <Text color="light-gray" size="sm" className={styles.text}>
            {settings.description}
          </Text>
          <form onSubmit={handleSubmit} className={styles.form}>
            <Input
              name="email"
              type="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter Your Email"
              error={errors.email && touched.email && errors.email}
            />
            <Checkbox
              name="terms"
              value="terms"
              onBlur={handleBlur}
              checked={values.terms}
              onChange={handleChange}
              label={<Agreement message="By signing up you agree to Granted’s" linkColor="green" />}
            />

            <Typography.Paragraph color="red" size="sm">
              {touched.terms && errors.terms}
            </Typography.Paragraph>

            <Button variant="primary" type="submit" uppercase loading={loading} disabled={loading}>
              {settings.buttonLabel}
            </Button>
          </form>
        </Grid>
      )}
    </Modal>
  )
}

export default SubscribePromocodeModal
