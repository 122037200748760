import { IncomingMessage } from 'http'
import { UAParser } from 'ua-parser-js'

import { EntityImage } from 'types'
import { PLACEHOLDER_IMAGE } from 'constants/constant'
import { ALL_STATES, COUNTRIES, STATES } from 'constants/addresses'
import { MOBILE_DEVICE, TABLET_DEVICE, DESKTOP_DEVICE } from '~services/constants'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isObject = (val: any) =>
  typeof val === 'object' && val !== null && !Array.isArray(val) && !(val instanceof Date)

export const removeEmptyKeys = <T>(
  obj: T,
  { filterValues = ['', null, undefined], removeEmptyArrays = true, filterKeys = [] } = {}
): T =>
  Object.keys(obj).reduce((resultObj, key) => {
    if (filterValues.includes(obj[key])) {
      return resultObj
    }

    if (filterKeys.includes(key)) {
      return resultObj
    }

    if (removeEmptyArrays && Array.isArray(obj[key]) && !obj[key].length) {
      return resultObj
    }

    if (isObject(obj[key])) {
      return {
        ...resultObj,
        ...{
          [key]: removeEmptyKeys(obj[key]),
        },
      }
    }

    return { ...resultObj, ...{ [key]: obj[key] } }
  }, {}) as T

const getDeviceTypeFromUA = ({ headers }: IncomingMessage): 'mobile' | 'desktop' => {
  const userAgent = headers['user-agent'] || headers['User-Agent']

  const { type } = new UAParser(userAgent as string).getDevice()

  return type === MOBILE_DEVICE || type === TABLET_DEVICE ? MOBILE_DEVICE : DESKTOP_DEVICE
}

export const getSSRScreenClass = (req: IncomingMessage): ThemeScreenClassSizesT => {
  if (!req) {
    return 'lg'
  }

  const device = getDeviceTypeFromUA(req)

  return device === 'mobile' ? 'xs' : 'lg'
}

export const getIdFromSlug = (slug: string): string => (slug || '').split('-')[0]

// TODO: we can try to call it in `Image` component, but we need to move all pictures to s3 firstly
export const getImageUrl = (
  img?: EntityImage.Details | string,
  width?: number,
  height?: number,
  optional?: { placeholder?: string }
): string => {
  let imagePath: string

  if (typeof img !== 'string') {
    imagePath = img
      ? `${img.path}.${img.extension}`
      : optional?.placeholder || PLACEHOLDER_IMAGE.getFullPath()
  } else {
    imagePath = img || PLACEHOLDER_IMAGE.getFullPath()
  }

  const dimensions = width && height ? `/${width}x${height}` : ''

  return `${process.env.NEXT_PUBLIC_IMAGE_HOST}${dimensions}${imagePath}`
}

export const stringToNumber = (value: string, length: number): number =>
  parseInt(value.replace(/\D/g, '').slice(0, length), 10)

export const formatCurrency = (value: number, toFixed = 2, symbol = '$'): string =>
  `${symbol}${value.toFixed(toFixed).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`

export const textToTitleCase = (text: string) =>
  (text.charAt(0).toUpperCase() + text.substr(1).toLowerCase()).split('_').join(' ')

export const smoothScroll = (top = 0): void =>
  window.scrollTo({
    top,
    behavior: 'smooth',
  })

export const isInFrame = () => typeof window !== 'undefined' && window.self !== window.top

export const getStateAbbreviation = (state: string): string => {
  const abbreviation = ALL_STATES.filter((el) => el.label.includes(state))[0]?.value

  return abbreviation || state
}

export const formatFeaturingArtists = (artists: Array<{ fullName: string }>): string =>
  artists && !!artists.length
    ? `featuring ${artists
        .map(({ fullName }) => fullName)
        .join(artists.length > 2 ? ', ' : ' and ')}`
    : ''

export const imagePreloader = (src: string) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.onload = resolve
    image.onerror = reject
    image.onabort = reject

    image.src = src
  })

export const snakeToCamel = (str: string) =>
  str
    .replace(/([-_]\w)/g, (g) => g[1].toUpperCase())
    .replace(/[A-Z]/, (g, i) => (i === 0 ? g.toLowerCase() : g))

export const flatAPropertyInArrayOfObj = (arrayWithObjectsToFlat, arrayPropertyOfObj) => {
  if (arrayWithObjectsToFlat?.length) {
    return arrayWithObjectsToFlat.reduce((acc, cur) => {
      if (cur[arrayPropertyOfObj]?.length) {
        const nested = flatAPropertyInArrayOfObj(cur[arrayPropertyOfObj], arrayPropertyOfObj)

        return [...acc, { ...cur, [arrayPropertyOfObj]: [] }, ...nested] // make the obj's prop empty to avoid nesting afterwards
      }

      return [...acc, cur]
    }, [])
  }

  return []
}

export const getLabelValueStatesList = (countries: string | string[]) => {
  let statesList = []

  if (typeof countries === 'string') {
    const getCountry = COUNTRIES.find(({ label }) => label === countries)
    statesList = STATES[getCountry.value] || []
  } else {
    const countriesWithStates = countries.map((country) => getLabelValueStatesList(country))

    statesList = [...Object.values(countriesWithStates).flatMap((arr) => arr)]
  }

  return statesList.length ? statesList.map(({ label }) => ({ label, value: label })) : []
}

export const copyToClipboard = (str) => {
  const el = document.createElement('textarea')
  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  const selected =
    document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
  if (selected) {
    document.getSelection().removeAllRanges()
    document.getSelection().addRange(selected)
  }
}

export const itemsToShow = (screen: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl', defaultItems = 4) => {
  if (screen === 'xs') return 1
  if (screen === 'sm') return 2
  if (screen === 'md') return 3
  return defaultItems
}

export const hasAnElementAsParent = (
  target: HTMLElement,
  element: string,
  stopElement = 'BODY'
): boolean => {
  if (!target || !element || !stopElement || !target.parentElement) return false
  if (target.parentElement.tagName === stopElement) return false

  if (target.parentElement.tagName === element) return true

  return hasAnElementAsParent(target.parentElement, element, stopElement)
}

export const hasKeys = (object: unknown, keys: Array<string>) =>
  isObject(object) && Object.keys(object).length
    ? keys.reduce((acc, curr) => !!object[curr] || acc, false)
    : false

export const getSellingFastLabelIndex = (slug, length) => {
  const num = Number(Date.now().toString().slice(7, 8))

  if (num < length) return num

  if (Math.abs(length - num) < length) return length - num

  if (slug.length < length) return slug.length

  if (Math.abs(length - slug.length) < length) return slug.length

  return length > 1 ? 1 : 0
}

export * from './time'
export * from './graphql'
export * from './tevo'
export * as time from './time-new'
