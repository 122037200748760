import { useRouter } from 'next/router'
import { useCallback, useEffect, useReducer } from 'react'
import { JOIN } from 'routes/paths'

type Coordinates = {
  latitude: number
  longitude: number
}

type Status = 'success' | 'failure' | 'waiting'

type Error = {
  message: string
  code: number
}

type State = {
  coordinates: Coordinates
  loading: boolean
  status: Status
  error: Error
}

type Action = { type: 'success'; coordinates: Coordinates } | { type: 'failure'; error: Error }

const initialState: State = {
  coordinates: undefined,
  status: 'waiting',
  error: undefined,
  loading: true,
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'success':
      return {
        ...state,
        coordinates: action.coordinates,
        status: 'success',
        loading: false,
      }

    case 'failure':
      return {
        ...state,
        error: action.error,
        status: 'failure',
        loading: false,
      }

    default:
      return state
  }
}

const EXCLUDE_ROUTES = [JOIN]

const useBrowserLocation = () => {
  const { pathname } = useRouter()
  const [state, dispatch] = useReducer(reducer, initialState)

  const onSuccess = useCallback((data: GeolocationPosition): void => {
    dispatch({
      type: 'success',
      coordinates: { latitude: data.coords.latitude, longitude: data.coords.longitude },
    })
  }, [])

  const onError = useCallback((data: GeolocationPositionError): void => {
    dispatch({
      type: 'failure',
      error: {
        message: data.message,
        code: data.code,
      },
    })
  }, [])

  useEffect(() => {
    if ('geolocation' in navigator && !EXCLUDE_ROUTES.includes(pathname)) {
      navigator.geolocation.getCurrentPosition(onSuccess, onError)
      return
    }

    dispatch({ type: 'failure', error: { message: 'Geolocation not supported', code: 0 } })
  }, [pathname, onSuccess, onError])

  return state
}

export default useBrowserLocation
