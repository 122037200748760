/* eslint-disable camelcase */
import { useEffect, useRef } from 'react'

import { removeEmptyKeys } from '~utils'
import { EventPayload, Purchase } from '.'

const EVENT_NAMES_MAP = {
  view_item: 'VIEW_ITEM',
  purchase: 'PURCHASE',
  add_to_cart: 'ADD_TO_CART',
}

type BranchPayload = {
  [key: string]: string | number
}

const mapDlPayloadToBranchEvent = (payload: EventPayload) => {
  if (payload.event === 'search_request') {
    return [
      'SEARCH',
      {
        search_query: payload.search_query,
      },
    ]
  }

  if (Object.keys(EVENT_NAMES_MAP).includes(payload.event)) {
    const eventName = EVENT_NAMES_MAP[payload.event]

    const e = (payload as Purchase).ecommerce

    const eventData = {
      currency: e.currency,
      revenue: e.value,
      description: e.items[0]?.item_name,
      star_name: e.items[0]?.star_name,
      event_date: e.items[0]?.event_date,
      venue_name: e.items[0]?.venue_name,
      tour_name: e.items[0]?.tour_name,
      event_name: e.items[0]?.event_name,
    } as BranchPayload

    if (payload.event === 'purchase') {
      eventData.transaction_id = payload.ecommerce.transaction_id
      eventData.coupon = payload.promocode
    }

    const contentItems =
      e.items?.map(({ item_id, item_name, quantity, price }) =>
        removeEmptyKeys({
          $content_schema: 'COMMERCE_PRODUCT',
          $og_title: item_name,
          $sku: item_id,
          $quantity: quantity,
          $price: price,
        })
      ) || []

    return [eventName, removeEmptyKeys(eventData), contentItems]
  }

  return []
}

const useBranch = () => {
  const branch = useRef(null)

  useEffect(() => {
    branch.current = window.branch
  }, [])

  const logDlEvent = (payload: EventPayload) => {
    if (
      (
        ['add_to_cart', 'purchase', 'view_item', 'search_request'] as Array<EventPayload['event']>
      ).includes(payload.event)
    ) {
      branch.current?.logEvent(...mapDlPayloadToBranchEvent(payload))
    }
  }

  return { logDlEvent }
}

export default useBranch
