import { ToastContainer, toast, ToastOptions } from 'react-toastify'
import classNames from 'classnames'
import { CSSProperties } from 'react'
import Icon, { IconTypes } from '~ui/Icon'

import 'react-toastify/dist/ReactToastify.css'
import styles from './notification.module.scss'

const Notification: React.FC = () => (
  <ToastContainer
    position={toast.POSITION.BOTTOM_CENTER}
    autoClose={4000}
    hideProgressBar
    pauseOnFocusLoss={false}
    pauseOnHover={false}
    closeOnClick={false}
    closeButton={false}
    draggable={false}
    className={styles.Notification}
  />
)

const toastMessage =
  (messageToShow: string, iconName: IconTypes) =>
  ({ closeToast }) =>
    (
      <div className={styles['toast-message']}>
        <Icon className={styles.icon} name={iconName} />
        <span>{messageToShow}</span>
        <button
          type="button"
          tabIndex={-1}
          onClick={closeToast}
          className={styles['circle-progress-container']}
        >
          <Icon className={styles['circle-progress']} name="circle" />
          <Icon className={styles['circle-child-overlapped']} name="cross" fill="currentColor" />
        </button>
      </div>
    )

export const notify = (message, icon?: IconTypes) =>
  toast(toastMessage(message, icon || 'check-filled'))

export const notifySuccess = (messageToShow, options?: ToastOptions) =>
  toast(toastMessage(messageToShow, 'check-filled'), {
    bodyClassName: styles.black,
    className: styles['green-background'],
    style: {
      '--progress-color': 'black',
    } as CSSProperties,
    ...options,
  })

export const notifyError = (messageToShow, options?: ToastOptions) =>
  toast(toastMessage(messageToShow, 'exclamation-mark'), {
    bodyClassName: classNames(styles.white),
    className: styles['red-background'],
    style: {
      '--progress-color': 'white',
    } as CSSProperties,
    ...options,
  })

export default Notification
