import { UserType } from 'gql'
import { useEffect, useRef } from 'react'
import useBranch from './branch'

// TODO: rewrite typing https://trello.com/c/AYm1XAMW/519-event-tracking
type ItemListName = 'packages' | 'tickets'

type EcommerceItem = {
  item_id: number | string
  item_name: string

  quantity?: number
  price?: number
}

type ExtendedEcommerceItem = EcommerceItem & {
  star_name: string
  event_date: string
  venue_name: string
  tour_name: string
  event_name: string
}

type MoreInfo = {
  event: 'more_info'
}

export type Purchase = {
  event: 'purchase'
  user_type: UserType
  promocode?: string
  ecommerce: {
    currency: string
    value: number
    transaction_id: string
    sales_as: string
    order_id: string
    items: Array<ExtendedEcommerceItem>
  }
}

type FinishAccount = {
  event: 'finish_account'
}

type SignupPopupSubmission = {
  event: 'signup_popup_submission'
}

type SignupPopupCopy = {
  event: 'signup_popup_copy'
}

type Login = {
  event: 'login'
  method: 'email-pass'
}

type SignUp = {
  event: 'sign_up'
  method: 'email-pass'
}

type ViewItem = {
  event: 'view_item'
  ecommerce: {
    currency?: string
    value?: number

    item_list_name: ItemListName
    items: Array<ExtendedEcommerceItem>
  }
}

type ViewItemList = {
  event: 'view_item_list'
  ecommerce: {
    item_list_name: ItemListName
    item_list_id: string | number
    items: Array<EcommerceItem>
  }
}

type AddToCart = {
  event: 'add_to_cart'
  ecommerce: {
    currency: string
    value: number
    item_list_name: ItemListName
    items: Array<ExtendedEcommerceItem>
  }
}

type RemoveFromCart = {
  event: 'remove_from_cart'
  ecommerce: {
    currency: string
    value: number
    items: Array<EcommerceItem>
  }
}

type SubscribeForMailing = {
  event: 'subsribe_for_mailing'
  type: 'tour-page' | 'sign_up'
}

type SeatmapInteraction = {
  event: 'seatmap_interaction'
}

type EventDetailPageView = {
  event: 'event_detail_page_view'
}

type PlaceABid = {
  event: 'place_a_bid'
  id: string
  type: number
}

type SearchClick = {
  event: 'search_click'
}

type SearchRequest = {
  event: 'search_request'
  search_query?: string
}

type OptimizeActivate = {
  event: 'optimize.activate'
}

type OptimizeActivate1 = {
  event: 'optimize.activate1'
}

type ClickOnEventCell = {
  event: 'click_on_event_cell'
}

type FollowStarVenue = {
  event: 'follow_star_venue'
}

type UnFollowStarVenue = {
  event: 'unfollow_star_venue'
}

type DateRangeFilterInteraction = {
  event: 'date_range_filter_interaction'
  method: string
}

type OrderComplete = {
  event: 'order_complete'
  order_value: number
  order_id: string
  currencyCode: string
}

export type EventPayload =
  | Purchase
  | FinishAccount
  | Login
  | SignUp
  | ViewItem
  | ViewItemList
  | AddToCart
  | SubscribeForMailing
  | RemoveFromCart
  | SeatmapInteraction
  | PlaceABid
  | EventDetailPageView
  | MoreInfo
  | SignupPopupSubmission
  | SignupPopupCopy
  | SearchClick
  | SearchRequest
  | ClickOnEventCell
  | OptimizeActivate
  | OptimizeActivate1
  | FollowStarVenue
  | UnFollowStarVenue
  | DateRangeFilterInteraction
  | OrderComplete

const useDataLayer = () => {
  const dataLayer = useRef(null)
  const { logDlEvent } = useBranch()

  useEffect(() => {
    dataLayer.current = window.dataLayer
  }, [])

  const push = (payload: EventPayload) => {
    // https://developers.google.com/tag-manager/enhanced-ecommerce#clear-ecommerce
    dataLayer.current?.push({ ecommerce: null })
    dataLayer.current?.push(payload)

    logDlEvent(payload)
  }

  return { push }
}

export default useDataLayer
