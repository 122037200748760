import { CSSProperties, FC, ReactHTML } from 'react'

import classnames from 'classnames'

import styles from './Text.module.scss'

export type TypographyProps = {
  bold?: boolean
  center?: boolean
  ellipsis?: boolean
  lineClamp?: number
  color?: GThemeColorsNew
  size?: GThemeTextSizes
  lineHeight?: GThemeLineHeights
  className?: string
  uppercase?: boolean
  italic?: boolean
}

type BaseProps = { Tag: keyof Pick<ReactHTML, 'span' | 'p'> }

interface TypographyComponents {
  Text: FC<TypographyProps>
  Paragraph: FC<TypographyProps>
}

const Base: FC<TypographyProps & BaseProps> = ({
  Tag,
  color = 'white',
  size = 'md',
  lineHeight,
  center,
  children,
  bold,
  ellipsis,
  lineClamp,
  className,
  uppercase,
  italic,
}) => (
  <Tag
    className={classnames(
      styles.typography,
      styles[Tag],
      styles[color],
      styles[size],
      styles[`lh-${lineHeight}`],
      {
        [styles.ellipsis]: ellipsis,
        [styles['line-clamp']]: lineClamp,
        [styles.center]: center,
        [styles.bold]: bold,
        [styles.uppercase]: uppercase,
        [styles.italic]: italic,
      },
      className
    )}
    {...(lineClamp && {
      style: {
        '--line-clamp': lineClamp,
      } as CSSProperties,
    })}
  >
    {children}
  </Tag>
)

const Text: FC<TypographyProps> = ({ ...props }) => <Base Tag="span" {...props} />

const Paragraph: FC<TypographyProps> = ({ ...props }) => <Base Tag="p" {...props} />

const Typography: TypographyComponents = {
  Paragraph,
  Text,
}

export default Typography
