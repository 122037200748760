import classnames from 'classnames'
import { useState, FC, InputHTMLAttributes, Ref } from 'react'

import { IconTypes } from '~ui/Icon'
import { Icon, IconButton } from '~ui'

import styles from './Input.module.scss'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  icon?: IconTypes
  withClear?: boolean
  error?: string
  large?: boolean
  withError?: boolean
  iconWithHandlerName?: IconTypes
  inputRef?: Ref<HTMLInputElement>
  iconClickHandler?: () => void
}

const Input: FC<InputProps> = ({
  icon,
  error,
  large,
  inputRef,
  withClear,
  iconWithHandlerName,
  iconClickHandler,
  withError = true,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('')

  return (
    <div className={styles.wrapper}>
      <div
        className={classnames(styles['input-wrapper'], {
          [styles.disabled]: props.disabled,
          [styles.withIcon]: icon,
          [styles.large]: large,
          [styles.error]: error,
        })}
      >
        <input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          ref={inputRef}
          {...(large ? { ...props, placeholder: ' ', required: true } : props)}
        />

        {icon && !large && (
          <Icon glow={false} name={icon} width="1em" height="1em" fill="currentColor" />
        )}

        {!withClear && iconClickHandler && iconWithHandlerName && (
          <IconButton
            iconProps={{ width: '1em', height: '1em' }}
            iconName={iconWithHandlerName}
            onClick={iconClickHandler}
            className={classnames(styles.icon, styles['icon-buttons'])}
          />
        )}

        {large && (
          <label
            htmlFor={props.name}
            className={classnames(styles.label, {
              [styles['label-error']]: !!error,
            })}
          >
            {icon && <Icon glow={false} name={icon} width="1em" height="1em" fill="currentColor" />}
            &nbsp;
            {error || props.placeholder}
          </label>
        )}
        {withClear && inputValue && (
          <IconButton
            className={styles['icon-buttons']}
            iconName="cross"
            iconProps={{ height: '0.7em', width: '0.7em', glow: false }}
            onClick={() => setInputValue('')}
          />
        )}
      </div>

      {!large && withError && (
        <span
          className={classnames(styles['error-message'], {
            [styles['error-message-active']]: !!error,
          })}
        >
          {error}
        </span>
      )}
    </div>
  )
}

export default Input
