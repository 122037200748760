import L, { LinkProps as NextLinkProps } from 'next/link'
import classnames from 'classnames'
import { FC } from 'react'

import { Icon } from '~ui'

import styles from './Link.module.scss'

type Sizes = 'lg' | 'md' | 'sm' | 'xs'

export type LinkProps = NextLinkProps & {
  rel?: string
  target?: string
  classes?: string
  tabIndex?: number
  external?: boolean
  uppercase?: boolean
  color?: GThemeColorsNew
  size?: Sizes
  bold?: boolean
}

const Link: FC<LinkProps> = ({
  rel,
  href,
  bold,
  color,
  target,
  classes,
  shallow,
  tabIndex,
  external,
  children,
  uppercase,
  size = 'md',
}) => (
  <L href={href} passHref shallow={shallow}>
    <a
      className={classnames(
        styles.link,
        styles[size],
        styles[color],
        {
          [styles.bold]: bold,
          [styles.external]: external,
          [styles.uppercase]: uppercase,
        },
        classes
      )}
      target={target}
      rel={rel}
      tabIndex={tabIndex}
    >
      {children}
      {external && <Icon className={styles.icon} glow={false} name="external-link" />}
    </a>
  </L>
)

export default Link
