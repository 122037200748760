import { UserType } from 'gql'
import CustomImage from '~utils/image'

export const SEARCH_ITEMS_PER_PAGE = 10

export const LIST_SENDGRID_NAMES = {
  THE_PUMP_FOUNDATION_AUCTION: 'The Pump Foundation Auction',
  SUBSCRIBE_FORM_TOUR_PAGE: 'Subscribe form (tour page)',
  NFT_MAILING: 'nft_mailing',
  SIGN_UP: 'sign_up',
  hideaway: 'hideaway',
  checkout: 'checkout',
  popup_sign_up: 'popup_sign_up',
  general_welcome_series: 'General Welcome Series',
  auction_sign_up: 'auction_sign_up',
  auction_requests: 'auction_requests',
}

export const GRANTED_SETTINGS_NAMES = {
  home: 'home-page-sections',
  promotion_subscribe_promocode: 'promocode-for-subscribe',
  vipTickets: 'vip-page-sections',
}

export const PLACEHOLDER_IMAGE = new CustomImage('/1652360119623-placeholder', 'png')

export const META_IMAGE = new CustomImage('/1641909788830-tour', 'jpg')

export const ALLOWED_USER_TYPES = [UserType.Admin, UserType.User]

export const EVENTS_NEAR_YOU_DISTANCE = 50

export const LOCAL_STORAGE_PROMOCODES = 'promocode_showed'
