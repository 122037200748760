import classnames from 'classnames'
import { InputHTMLAttributes, FC, ReactNode } from 'react'
import styles from './Checkbox.module.scss'

interface ButtonProps extends InputHTMLAttributes<HTMLElement> {
  label: string | ReactNode
  indeterminate?: boolean
  uppercase?: boolean
  checkboxClasses?: string
  rounded?: 'rounded-full' | string
}

const Checkbox: FC<ButtonProps> = ({
  label,
  indeterminate,
  uppercase,
  checkboxClasses,
  rounded = 'rounded-full',
  ...props
}) => (
  <label className={styles.wrapper} htmlFor={props.id}>
    <input className={styles.input} type="checkbox" {...props} />
    <span
      className={classnames(
        styles.checkbox,
        rounded,
        {
          [styles['checkbox-indeterminate']]: indeterminate,
        },
        checkboxClasses
      )}
    />
    <span className={classnames(styles.label, { [styles.uppercase]: uppercase })}>{label}</span>
  </label>
)

export default Checkbox
