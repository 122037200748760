export const PRIVACY_POLICY =
  'http://support.granted.co/hc/en-us/articles/360055479911-Privacy-Policy'

export const TERMS_AND_CONDITIONS =
  'http://support.granted.co/hc/en-us/articles/360055058592-Terms-of-Service'

export const CONTACT_US = 'http://support.granted.co/hc/en-us/articles/360055478731'

export const FAQs = 'http://support.granted.co'

export const REWARD_FAQ =
  'https://support.granted.co/hc/en-us/articles/10893927498004-Granted-Rewards-FAQ'

export const CAREERS = 'https://granted.breezy.hr/'

export const WHAT_IS_MAX_BIDDING = 'https://support.granted.co/hc/en-us/articles/360055057832'

export const SUBMIT_FORM = 'https://support.granted.co/hc/en-us/requests/new'

export const INSTAGRAM = 'https://www.instagram.com/granted/'
