import classnames from 'classnames'
import { FC, MouseEvent, useCallback, useEffect, useState } from 'react'
import ReactPortal from '~shared/ReactPortal'
import { Icon } from '~ui'

import styles from './Modal.module.scss'

export type ModalProps = {
  isOpen: boolean
  onClose: () => void
  modalClassName?: string
  maskClassName?: string
  wrapperClassName?: string
  closeButton?: boolean
}

const Modal: FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  maskClassName,
  modalClassName,
  wrapperClassName,
  closeButton = true,
}) => {
  const [isAnimation, setIsAnimation] = useState(false)
  const [isMounted, setIsMounted] = useState(isOpen)

  const onKeydownHandler = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') onClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClickHandler = useCallback((e: MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) onClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setIsAnimation(true)

    if (isOpen) {
      setIsMounted(true)
      document.addEventListener('keydown', onKeydownHandler)
      document.body.style.overflow = 'hidden'
    } else {
      document.removeEventListener('keydown', onKeydownHandler)
      document.body.style.overflow = 'unset'
    }

    return () => {
      document.removeEventListener('keydown', onKeydownHandler)
      document.body.style.overflow = 'unset'
    }
  }, [isOpen, onKeydownHandler])

  const onAnimationEnd = useCallback(() => {
    if (!isOpen) {
      setIsMounted(false)
    }
    setIsAnimation(false)
  }, [isOpen])

  if (!isMounted) return null

  return (
    <ReactPortal selector="#modal-root">
      <div>
        <div
          className={classnames(styles.mask, maskClassName, {
            [styles['mask-fade-in']]: isOpen && isAnimation,
            [styles['mask-fade-out']]: !isOpen && isAnimation,
          })}
        />
        <div
          className={classnames(styles.wrapper, wrapperClassName)}
          onClick={onClickHandler}
          aria-hidden="true"
        >
          <div
            className={classnames(styles.modal, modalClassName, {
              [styles['modal-fade-in']]: isOpen && isAnimation,
              [styles['modal-fade-out']]: !isOpen && isAnimation,
            })}
            onAnimationEnd={onAnimationEnd}
          >
            {closeButton && (
              <button type="button" className={styles.button} onClick={onClose}>
                <Icon name="cross" />
              </button>
            )}
            {children}
          </div>
        </div>
      </div>
    </ReactPortal>
  )
}

export default Modal
