import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

const ReactPortal = ({ children, selector }) => {
  const ref = useRef<HTMLElement>()
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    ref.current = document.querySelector(selector)
    setMounted(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return mounted ? createPortal(children, ref.current) : null
}

export default ReactPortal
