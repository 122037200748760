class CustomImage {
  // eslint-disable-next-line no-useless-constructor
  public constructor(public path: string, public extension: string) {}

  public getFullPath(): string {
    return `${this.path}.${this.extension}`
  }
}

export default CustomImage
