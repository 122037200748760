import { FC, createRef } from 'react'
import { Input } from '~ui'
import { InputProps } from '~ui/Input'

const InputPassword: FC<InputProps> = ({
  value,
  error,
  onBlur,
  onChange,
  autoComplete,
  id = 'password',
  type = 'password',
  name = 'password',
  placeholder = 'Password',
}) => {
  const inputRef = createRef<HTMLInputElement>()
  const visibilityIconClickHandler = () => {
    if (inputRef.current) {
      inputRef.current.type = inputRef.current.type === 'text' ? 'password' : 'text'
    }
  }

  return (
    <Input
      id={id}
      type={type}
      name={name}
      error={error}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      inputRef={inputRef}
      iconWithHandlerName="eye"
      placeholder={placeholder}
      autoComplete={autoComplete}
      iconClickHandler={visibilityIconClickHandler}
    />
  )
}

export default InputPassword
